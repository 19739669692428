import BpkLink from '@skyscanner/backpack-web/bpk-component-link';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import { getLocalFormattedDateFromISO } from '../localisation-helpers';

import type { AuthorProps } from 'common-types/types/AuthorProps';

import STYLES from './Author.module.scss';

const Author = ({
  articleCreatedDate,
  authorHref,
  authorImage,
  authorName,
  fallbackArticleCreatedLocalised,
}: AuthorProps) => {
  const articleCreatedLocalised =
    typeof window !== 'undefined'
      ? getLocalFormattedDateFromISO({
          isoDate: articleCreatedDate,
        })
      : fallbackArticleCreatedLocalised;

  return (
    <div className={STYLES.Author}>
      <img className={STYLES.AuthorImage} alt="" src={authorImage} />
      <div>
        <BpkText tagName="p" textStyle={TEXT_STYLES.heading5}>
          <time dateTime={articleCreatedDate}>{articleCreatedLocalised}</time>
        </BpkText>

        <BpkLink rel="author" href={authorHref}>
          {authorName}
        </BpkLink>
      </div>
    </div>
  );
};

export default Author;
